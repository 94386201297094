import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import CourseFooterNext from "../../../../../components/course-footer-next"
import TaskSidebar from "../../../../../components/task-sidebar"
import NotationGroup from "../../../../../components/notation-group"
import Paragraph from "../../../../../components/paragraph"
import Image from "../../../../../components/image"
import Grid from "../../../../../components/grid"
import Divider from "../../../../../components/divider"
import Note from "../../../../../components/note"
import PoemBildelementeNotation from "../../../../../content/kurse/hoelderlins-reisen/02-reiseerinnerung/poem-bildelemente-notation"

import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  const data = useStaticQuery(graphql`
    query BildelementeQuery {
      heidelberg: file(relativePath: { eq: "kurse/klemm-heidelberg.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/02-reiseerinnerung/bildelemente/loesung" />
      }
      navigation={navigation}
      progress={{
        chapter: 2,
        current: 2,
        total: 7,
      }}
    >
      <Seo title="Bildelemente" />
      <Box>
        <Stack>
          <Heading as="h2" level={2}>
            Bildelemente
          </Heading>
          <Grid columns={[1, 2]}>
            <Paragraph>
              Die Fotografin Barbara Klemm hat die Stadt Heidelberg für die
              Ausstellung ›Hölderlins Orte‹ mit der Schwarz-Weiß-Kamera
              fotografiert. Sieh dir das Bild genau an. Was kannst du im
              Vordergrund erkennen? Was entdeckst du im Hintergrund?
            </Paragraph>

            <Image
              image={data.heidelberg.childImageSharp.gatsbyImageData}
              alt="Heidelberg"
            />
          </Grid>

          <Divider size={4} />
          <TaskSidebar
            main={
              <NotationGroup
                onNotationsUpdated={(notations) => {
                  setAnswer({
                    courseId: "hoelderlins-reisen",
                    chapterId: "02-reiseerinnerung",
                    taskId: "bildelemente",
                    answer: notations,
                  })
                }}
              >
                <PoemBildelementeNotation />
              </NotationGroup>
            }
            sidebar={
              <Note variant="task">
                Welche Elemente aus dem Bild findest du im Gedicht wieder?
                Markiere die Worte im Gedicht, indem du sie anklickst.
              </Note>
            }
          />
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
